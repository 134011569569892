<template lang="pug">
div
  h3 Комиссия
  .content
    div.commission.commission-taker
      h4.profile-content__commissions тейкера
      .commission-value
        | {{ commissions.client }}%
    div.commission.commission-maker
      h4.profile-content__commissions мейкера
      .commission-value
        | {{ commissions.offer }}%
  SpecialCommissionsAlert(v-if="commissions.special", :specialCommissionsPeriod="commissions.special")
</template>

<script>
import SpecialCommissionsAlert from "@/components/Commissions/SpecialCommissionsAlert.vue";

export default {
  components: {
    SpecialCommissionsAlert,
  },
  props: {
    commissions: {
      type: Object,
      required: true,
      validate(object) {
        return (!object.special || object.special.to && object.special.from)
          && 'offer' in object
          && 'client' in object
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  column-gap: 28px;
}

h3, h4 {
  font-size: 18px;
  color: #000000;
}

h3 {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 600;
}

h4 {
  margin-top: 0px;
  margin-bottom: 6px;
  font-weight: 400;
}

.commission-value {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  margin-left: -1px;
}
</style>