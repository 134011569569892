<template lang="pug">
div(
  v-if="specialOn"
  :class=`[
    'special-commissions-alert-container',
    soonEnding ? 'special-commissions-alert-container__soon-ending' : ''
  ]`
)
  .title-container
    div(v-if="soonEnding")
      .el-icon-warning.el-alert__icon.is-big
    div(v-else)
      .el-icon-info.el-alert__icon.is-big
    .title Действуют специальные комиссии
  p.time-left(v-if="soonEnding") Закончатся {{ timeLeftHumanized }}
  p #[span.nobr Начались #[span.date {{ formatDate(specialCommissionsPeriod.from) }},]] #[span.nobr закончатся #[span.date {{ formatDate(specialCommissionsPeriod.to) }}]]
</template>

<script>
import specialCommissionsTimer from '@/components/mixins/specialCommissionsTimer.js';

export default {
  name: 'SpecialCommissionsAlert',
  mixins: [
    specialCommissionsTimer,
  ],
  props: {
    specialCommissionsPeriod: {
      type: Object,
      required: true,
      validate(object) {
        return typeof object.to === 'string' && typeof object.from === 'string';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.nobr {
  display: inline-block;
}

.special-commissions-alert-container {
  color: #555555;

  &__soon-ending {
    color: #e6a23c;
  }

  .title-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    
    .title {
      font-weight: bold;
      line-height: 1.2;
    }
  }
  
  p {
    margin-bottom: 0.25rem;
  }

  .date {
    letter-spacing: 0.02rem;
  }
}
</style>
