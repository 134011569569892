<template lang="pug">
.main-content.layout-main__centering.container.pt-3
  .commissions__container

    .commissions__row
      // Values card -----------------------------------------
      .commissions__card.commissions__card-values()
        .card__title.pb-2 Торговля
        .commissions__row.gap-32
          .value
            .value__title
              .card__description Оффер
              el-tooltip(effect='dark' placement='bottom')
                img.icon(src="/awesome-icons/info-mini.svg")
                div(slot="content")
                  | Оффер — автор объявления
            .value__percents
              span {{ commissions.offer + '%' }}
          .value
            .value__title
              .card__description Клиент
              el-tooltip(effect='dark' placement='bottom')
                img.icon(src="/awesome-icons/info-mini.svg")
                div(slot="content")
                  | Клиент — тот, кто обращается по объявлению и открывает сделку
            .value__percents
              span {{ commissions.client + '%' }}
        .commissions__column.pt-3(v-if="specialOn")
          .card__subtitle Действуют особые условия
          .card__description.mt-1 с {{ this.formatDate(this.specialCommissionsPeriod.from) }}
          .card__description по {{ this.formatDate(this.specialCommissionsPeriod.to) }}
      // Pay-in and pay-out card -------------------------------
      .commissions__card.commissions__card-pay()
        .card__title.pb-2 Вывод средств
        .commissions__column.gap-14
          .card__item
            .card__description Перевод средств на адреса Finex
            .card__subtitle бесплатно
          .card__item(v-if="standardPayOut")
            .card__description Стандартный вывод
            .card__subtitle {{standardPayOut}} BTC
          .card__item(v-if="fastPayOut")
            .card__description Ускоренный вывод
            .card__subtitle {{fastPayOut}} BTC
      // Limits card -------------------------------------------
      .commissions__card.commissions__card-limits()
        .card__title.pb-2 Лимиты
        .commissions__column.gap-14
          .card__item
            .card__description Минимальный ввод или вывод
            .card__subtitle от 0.0001 BTC
          .card__item
            .card__description Максимальный ввод или вывод
            .card__subtitle без ограничений
          .card__item
            .card__description Лимиты на торговлю
            .card__subtitle без ограничений

    .commissions__row
      .commissions__card.commissions__card-info
        .commissions__row.gap-40
          .commissions__column.gap-14
            .card__title.pb-3 Общая информация о комиссиях
            p.card__text Все расчеты производятся в биткоинах. Владелец объявления является
              span.card__text-bold &nbsp;оффером,
              | &nbsp;а инициатор сделки –
              span.card__text-bold &nbsp;клиентом.
            p.card__text Стандартная комиссия оффера составляет
              span.card__text-bold &nbsp; {{standardGlobalCommissions.offer}}.
              | &nbsp;Клиента –
              span.card__text-bold &nbsp; {{standardGlobalCommissions.client}}
              | , но не менее установленного &nbsp;
              el-tooltip(placement="right")
                div(slot="content")
                  template(v-for="(commission, name) in getGlobalCommissions.min_fiat")
                    div {{ name }} : {{ commission }}
                span.card__text-bold.card__text-underline минимума
              | &nbsp;за сделку.
            p.card__text Комиссии банковских и прочих фиатных платежных систем должны быть заложены в
              span.card__text-bold &nbsp;курс объявления.
            p.card__text Трейдеры, предоставляющие высокую ликвидность или большой объем торгов, могут
              span.card__text-bold &nbsp;снизить стандартную комиссию
              | &nbsp;для своего аккаунта.
          .commissions__column.gap-14
            .card__title.pb-3 FAQ по комиссиям
            .card__item
              p.card__text.card__text-bold Q: Мой депозит BTC потерялся и не дошел до аккаунта.
              p.card__text
                span.card__text-bold A:
                | &nbsp;Проверьте транзакцию (txid) на наличие подтверждений в одном из обозревателей блокчейна, например &nbsp;
                a.card__text-bold.card__text-underline.text-blue(href="https://blockchair.com/bitcoin" target="_blank") Blockchair
                | . &nbsp; Транзакция будет обработана после третьего подтверждения сети (confirmation). На скорость обработки транзакции влияет размер комиссии, поставленной при переводе.
            .card__item
              p.card__text.card__text-bold Q: После возврата в результате AML-проверки мне пришло меньше биткоина. Вы берете комиссию за это?
              p.card__text
                span.card__text-bold A:
                | &nbsp;Нет, мы не берем комиссию за проверку или возврат. Недостающий биткоин был уплачен в качестве стандартной платы за перевод в блокчейне при возврате.
            .card__item
              p.card__text.card__text-bold Q: Могу ли я сам настроить комиссию за вывод средств?
              p.card__text
                span.card__text-bold A:
                | &nbsp;Вам доступны только два способа вывода средств – стандартный и ускоренный. Последний будет иметь агрессивную комиссию для более быстрой обработки транзакции.
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_GLOBAL_COMMISSIONS } from '@/store/actions/commission';
import specialCommissionsTimer from '@/components/mixins/specialCommissionsTimer.js';
import Commissions from "@/components/Commissions/Commissions.vue";
import SpecialCommissionsAlert from "@/components/Commissions/SpecialCommissionsAlert.vue";

export default {
  components: {
    Commissions,
    SpecialCommissionsAlert,
  },
  mixins: [
    specialCommissionsTimer,
  ],
  computed: {
    ...mapGetters([
      'getGlobalCommissions',
      'isAuthenticated',
    ]),
    commissions() {
      return {
        offer: this.getGlobalCommissions.special ?
          this.getGlobalCommissions.special.offer_commission :
          this.getGlobalCommissions.global.offer_commission,
        client: this.getGlobalCommissions.special ?
          this.getGlobalCommissions.special.client_commission :
          this.getGlobalCommissions.global.client_commission,
      };
    },
    specialCommissionsPeriod() {
      if (!this.getGlobalCommissions.special) return {}
      return {
        from: this.getGlobalCommissions.special.from,
        to: this.getGlobalCommissions.special.to,
      };
    },
    fastPayOut() {
      return this.getGlobalCommissions?.pay_out?.fast?.btc?.commission
    },
    standardPayOut() {
      return this.getGlobalCommissions?.pay_out?.standard?.btc?.commission
    },
    standardGlobalCommissions() {
      return {
        client: this.getGlobalCommissions?.global?.client_commission? `${this.getGlobalCommissions.global.client_commission}%` : '',
        offer: this.getGlobalCommissions?.global?.offer_commission? `${this.getGlobalCommissions.global.offer_commission}%` : ''
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(GET_GLOBAL_COMMISSIONS)
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1030px;
}
.commissions__special {
  display: flex;
  gap: 6px;
  padding-top: 22px;
}
.commissions__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.commissions__row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.commissions__column {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}
.commissions__card {
  background-color: $blue50;
  border: none;
  border-radius: 16px;
  padding: 14px 16px 16px;

  &-values {
    flex: 1;
    min-width: 322px;
    .value {
      &__title {
        display: flex;
        gap: 2px;
      }
      &__percents {
        color: $gray800;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  &-pay, &-limits {
    flex: 1;
    min-width: 322px;
  }
  &-info {
    width: 100%;
    padding: 14px 16px 45px 16px;
  }
}

.card {
  &__title {
    color: $gray800;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  &__subtitle {
    color: $gray800;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  &__text {
    color: $gray800;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    &-bold {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
    &-underline {
      text-decoration: underline !important;
      text-underline-offset: 3px;
    }
  }
  &__description {
    color: $gray600;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

.gap {
  &-14 {gap: 14px}
  &-24 {gap: 24px}
  &-32 {gap: 32px}
  &-40 {gap: 40px}
}

.icon {
  width: 16px;
  height: 16px;
}

a.text-blue, .text-blue {
  color: $blue600;
}
a {
  &:visited, &:active, &:focus, &:hover {color: $blue800 !important; cursor: pointer}
}
p {
  margin: 0;
}

</style>
