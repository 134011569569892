import { render, staticRenderFns } from "./SpecialCommissionsAlert.vue?vue&type=template&id=3a4287e2&scoped=true&lang=pug&"
import script from "./SpecialCommissionsAlert.vue?vue&type=script&lang=js&"
export * from "./SpecialCommissionsAlert.vue?vue&type=script&lang=js&"
import style0 from "./SpecialCommissionsAlert.vue?vue&type=style&index=0&id=3a4287e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4287e2",
  null
  
)

export default component.exports